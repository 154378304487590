import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.bbq.html',
  styleUrls: ['./app.component.css']
})
export class bbq {
}

