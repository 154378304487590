import { NgModule } from '@angular/core';
import { RouterModule,Router, NavigationEnd } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { backgroundVideo } from './app.backgroundVideo';

import { bbq } from './app.bbq';
import { bbq2 } from './app.bbq2';
import { contact_faq } from './app.contact_faq';
import { info} from './app.info';
import { location } from './app.location';
import { media } from './app.media';
import { partners } from './app.partners';
import { tickets } from './app.tickets';

@NgModule({
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  declarations: [
    bbq,
    bbq2,
    contact_faq,
    info,
    location,
    media,
    partners,
    AppComponent,
    backgroundVideo,
    tickets
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
      { path: "", component: backgroundVideo },
      { path: "info", component: info },
      { path: "location", component: location },
      { path: "tickets", component: tickets },
      { path: "bbq", component: bbq },
      { path: "bbq-bevestiging", component: bbq2 },
      { path: "media", component: media },
      { path: "partners", component: partners },
      { path: "contact-faq", component: contact_faq }
    ])
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
      }
    });
  }
}
