import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.info.html',
  styleUrls: ['./app.component.css']
})
export class info implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}
