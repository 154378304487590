import { Component } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.backgroundVideo.html',
  styleUrls: ['./app.backgroundVideo.css']
})
export class backgroundVideo {
  constructor() {
    $(document).ready(function () {
      setTimeout(function () {
        let Player = <HTMLVideoElement>$("video.landing").get(0);
        Player.muted = true;
        Player.play();
      }, 300);
    });
  }
}

